.singlePost {
  flex: 9;
  font-family: "Lora", serif;
}

.singlePostWrapper_info {
  display: flex;
  margin-bottom: 100px;
}

.singlePostWrapper_info-left,
.singlePostWrapper_info-right {
  width: 49%;
}

.singlePostWrapper_info-right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: "Lora", serif;
}

.singlePostWrapper_info-right p {
  font-size: 1.4rem;
}

.singlePostWrapper {
  padding: 20px;
  padding-right: 0;
  display: flex;
  flex-direction: column;
}

.singlePostImg {
  width: 50%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
  margin: 0 auto 20px;
}

p span {
  font-weight: 800;
  font-size: 1.5rem;
  font-weight: 700;
  color: rgb(255, 60, 0);
}

.singlePostTitleInput {
  font-size: 1.4rem;
  border: none;
  color: gray;
}

.singlePostTitleInput:focus {
  outline: none;
}

.singlePostEdit {
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  align-items: center;
}

.singlePostEdit i {
  color: white;
}

.singlePostEdit_btn {
  display: flex;
  flex-direction: row;
}

.singlePostIcon:first-child {
  color: teal;
}

.singlePostIcon:last-child {
  color: white;
}

.singlePostInfo {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: #b39656;
}

.singlePostTitle,
.singlePostDesc {
  margin-bottom: 15px;
}

.singlePostWrapper_info-right i {
  color: gray;
  font-size: 1.1rem;
  margin-right: 10px;
}

.btn {
  width: 100px;
  background-color: #4464bb;
  color: #fff;
  border-radius: 3px;
  border: 0;
  box-shadow: 0 1px 2px rgb(0 0 0 / 50%);
  font-size: 1.1rem;
  padding: 5px 10px;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  margin-right: 10px;
}

.delete {
  background: #f53f3f;
}

.btn:hover {
  background: #25b850;
}

.singlePostDescInput {
  border: none;
  color: #666;
  font-size: 1.4rem;
  line-height: 25px;
}

.singlePostDescInput:focus {
  outline: none;
}

.singlePostButton {
  width: 100px;
  border: none;
  background-color: teal;
  padding: 8px 10px;
  margin-right: 10px;
  color: white;
  border-radius: 3px;
  cursor: pointer;
  align-self: flex-end;
  font-size: 0.9rem;
  font-weight: 600;
  text-align: center;
}

.imagePreviewRemoveButton {
  position: absolute;
  cursor: pointer;
  right: 0;
}

.imagePreview {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.imagePreviewItem {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
}

.imagePreviewItem img {
  width: 100%;
  object-fit: cover;
}
