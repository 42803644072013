.home {
  display: flex;
}

.home_btn_filter {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 20px 0;
}

.home_btn_filter button {
  align-items: center;
  background-clip: padding-box;
  background-color: #fa6400;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica,
    Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0 5px;
  min-height: 3rem;
  padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
}

.home_btn_filter button:hover {
  transform: translateY(-1px);
}

.active {
  background-color: #00c832 !important;
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px !important;
  transform: translateY(0) !important;
}
