.login {
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
      rgba(0, 0, 0, 0.5),
      rgba(92, 92, 92, 0.5)
    ),
    url("https://images.unsplash.com/photo-1543892653-a8c35efe6445");
  background-size: cover;
  color: white;
}

.loginTitle {
  font-size: 50px;
}

.loginForm {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.loginForm > label {
  margin: 10px 0;
}

.loginInput {
  padding: 10px;
  background-color: white;
  border: none;
  border-radius: 10px;
}

.loginInput:focus {
  outline: none;
}

.loginButton {
  margin-top: 20px;
  cursor: pointer;
  background-color: lightcoral;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 10px;
  text-align: center;
}

.loginButton:disabled{
  cursor: not-allowed;
  background-color: rgb(243, 144, 144);
}

.loginRegisterButton {
  position: absolute;
  top: 60px;
  right: 20px;
  background-color: teal;
  cursor: pointer;
  padding: 10px;
  border: none;
  color: white;
  border-radius: 10px;
}
