.write {
  padding-top: 50px;
}

.writeImg {
  display: flex;
  justify-content: center;
  width: 300px;
  height: 100%;
  margin: 0 auto 20px;
  object-fit: cover;
}

.writeForm {
  position: relative;
}

.writeForm_fileInput {
  margin: 0 170px 20px;
}

.writeFormGroup {
  margin-left: 150px;
  display: flex;
  align-items: center;
}

.writeFormGroup i {
  color: gray;
  margin-left: 20px;
}

.writeFormGroupOptions {
  margin: 30px 0 30px 170px;
  display: flex;
  align-items: center;
  font-size: 1.3rem;
}

.writeFormGroupOptions label {
  margin-right: 50px;
  margin-left: 10px;
}

.writeIcon {
  width: 25px;
  height: 25px;
  font-size: 20px;
  border: 1px solid;
  border-radius: 50%;
  color: rgb(129, 125, 125);
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.writeUpload {
  width: 100px;
  height: 25px;
  font-size: 20px;
  border: 1px solid;
  border-radius: 3px;
  padding: 3px 5px;
  color: rgb(129, 125, 125);
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
}

.writeInput {
  font-size: 30px;
  border: none;
  padding: 20px;
  width: 70vw;
}

.writeInput::placeholder {
  color: rgb(189, 185, 185);
  font-weight: 400;
}

.writeInput:focus {
  outline-style: none;
}

.writeText {
  width: 70vw;
  height: 5vh;
  font-family: inherit;
  font-size: 20px;
}

.writeSubmit {
  position: absolute;
  top: 20px;
  right: 50px;
  color: white;
  background-color: teal;
  padding: 10px;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.writeForm p {
  margin: 0 170px;
  font-size: 1.4rem;
}
