.main_form {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(60, 66, 87, 0.75);
  font-family: "Josefin Sans", sans-serif;
}

.formbg {
  margin: 0px auto;
  padding: 20px 30px;
  width: 70%;
  max-width: 348px;
  background: white;
  border-radius: 4px;
  box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 14px 0px,
    rgba(0, 0, 0, 0.12) 0px 3px 6px 0px;
}

.formbg-title {
  text-align: center;
}

.field {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.field .btn-submit {
  font-size: 16px;
  line-height: 28px;
  width: 48%;
  min-height: 44px;
  margin-top: 20px;
  border: unset;
  border-radius: 4px;
  outline-color: rgb(84 105 212 / 0.5);
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px;
  cursor: pointer;
  font-family: "Josefin Sans", sans-serif;
}

.field .btn-submit:hover {
  background-color: #ccc;
  font-weight: 600;
  font-family: "Josefin Sans", sans-serif;
}

.change-password_title {
  margin: 10px 0;
}

.change-password_input {
  width: 90%;
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
